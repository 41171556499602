import { Fragment, useState, useMemo, useEffect } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import Link from 'next/link';
import { Avatar } from 'primereact/avatar';
import * as crypto from '@/common/Crypto';
import ModalLogin from '../modal/user/login';
import ModalRegister from '../modal/user/register';
import { Dialog } from 'primereact/dialog';
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem } from '@module/nav';
import Sidebar from '@module/sidebar';

// const HeaderNotification = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     width: 100%;
// `
// const Nav = styled.nav`
//     background-color: #fff;
//     height: 80px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     font-family: 'Roboto', sans-serif;

//     @media (max-width: 960px) {
//         transition: 0.8s all ease;
//     }

//     .container-fluid {
//         height: 80px;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//     }

//     .none-event{
//         pointer-events: none;
//     }

//     .active-text{
//         color: #F3941F;
//         font-weight: bold;
//     }
// `;

// const NavLogo = styled.div`
//     display: flex;
//     height: 60px;
//     width: 60px;

//     img {
//         width: 100%;
//         height: 100%;
//         object-fit: contain;
//         cursor: pointer;
//     }
// `;

// const NavItem = styled.div`
//     display: flex;
//     min-width: 200px;
//     height: 60px;
//     align-items: center;
//     justify-content: flex-end;

//     .bell{
//         color: #000;
//     }

//     .active{
//        color: #F3941F;
//        font-weight: bold;
//     }

//     .active .bell{
//         color: #F3941F!important;
//     }
// `

// const NavMenuItem = styled.div`
// display: flex;
// align-items: center;
// font-size: 15px;
// padding: 0 15px;
// cursor: pointer;
// position: relative;
// height: 100%;

// & a{
//     color: #000;
//     width: 100%;
//     justify-content: center;
//     position: relative;
//     flex-direction: column;
// }

// .liner-hidden{
//     visibility: hidden;
// }

// .liner-visible{
//     visibility: visible;
// }

// .a-flex-start{
//     display: flex;
//     align-items: flex-start;
// }

// .a-flex-end{
//     display: flex;
//     align-items: flex-end;
// }

// @keyframes line-left-right {
//     0% {
//         width: 0%;
//         visibility: visible;
//     }

//     100% {
//         width: 100%;
//     }
// }


// @keyframes line-right-left {
//     0% {
//         width: 100%;
//         visibility: visible;
//     }

//     100% {
//         width: 0%;
//         visibility: hidden;
//     }
// }

// a:hover{
//     text-decoration: none;
// }

// .content-liner{
//     width: 100%;
//     height: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     height: calc(100% - 2px);
// }

// .liner{            
//     width: 100%;
//     height: 2px;
//     background-color: #F3941F;
//     visibility: hidden
// }  

// .line-start{
//     animation-name: line-left-right;
//     animation-duration: 0.5s;
// }

// .line-end{
//     animation-name: line-right-left;
//     animation-duration: 0.5s;
// }

// .showLiner{
//     display: block;
// }
// `;

// export const SidebarContainer = styled.aside`
//     position: fixed;
//     z-index: 9999;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(255,255,255,0.9);
//     color: #000;
//     display: grid;
//     align-items: center;
//     left: 0;
//     transition: 0.3s all ease-in-out;
//     opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
//     top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
// `;

const ButtonAction = styled.div`
    display: flex;
    align-items: center;
    border: none;
    box-shadow: none;
    justify-content: center;
    background-color: transparent;      
    font-family: 'Roboto', sans-serif;      
    font-size: 15px;
    padding: 5px;
    cursor: pointer;
`

const MenuDefault = () => {
    const router = useRouter();
    const [account, setAccount] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [isRegister, setIsRegister] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        console.log(isOpen)
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        const account = crypto.GetProfile();
        if (account) {
            setAccount(account);

            const {
                nameUser
            } = account;

            const requireUpdate = localStorage.getItem('require-update');

            if (!requireUpdate) {
                return;
            }

            if (!nameUser) {
                router.push("/update-info");
                localStorage.setItem("require-update", true);
            }
        }
    }, [])

    const renderButtonAccount = useMemo(() => {
        if (account && router.asPath) {
            return (
                <Link legacyBehavior href="/my-profile" passHref>
                    <ButtonAction className={(router.asPath === `/my-profile` || router.asPath === `/my-profile#ng`) ? `active` : null}>
                        <span className='none-event' style={{ marginRight: 10, textTransform: `capitalize` }}>{account.nameUser}</span>
                        <Avatar style={{ width: 40, height: 40 }} className='none-event' label={(account && account.nameUser) ? (account.nameUser).substr(0, 1) : "D"} size="large" shape="circle" />
                        {/* <span className='pi pi-angle-down none-event' style={{ marginLeft: 5, fontSize: 14 }}></span> */}
                    </ButtonAction>
                </Link>
            )
        }
        return null;
    }, [account, router.asPath]);

    const renderNotification = () => {
        return (
            <Link legacyBehavior href={`/my-profile#nt`} passHref>
                <ButtonAction className={(router.asPath === `/my-profile#nt`) ? `active` : null}>
                    <Avatar style={{ width: 40, height: 40, marginLeft: 10 }} className='p-overlay-badge none-event' icon="pi pi-bell bell" size="large" shape="circle" />
                </ButtonAction>
            </Link>
        )
    }

    const onFocusIn = (e, index) => {
        e.preventDefault();
        document.querySelector(`.liner-${index}`).classList.add('line-start');
        document.querySelector(`.liner-${index}`).classList.add('liner-visible');
        document.querySelector(`.liner-${index}`).classList.remove('line-end');
        document.querySelector(`.start-animation-${index}`).classList.add('a-flex-start');
        document.querySelector(`.start-animation-${index}`).classList.remove('a-flex-end');
    }

    const onFocusOut = (e, index) => {
        e.preventDefault();
        document.querySelector(`.liner-${index}`).classList.remove('line-start');
        document.querySelector(`.liner-${index}`).classList.add('line-end');
        document.querySelector(`.liner-${index}`).classList.add('liner-hidden');
        document.querySelector(`.start-animation-${index}`).classList.remove('a-flex-start');
        document.querySelector(`.start-animation-${index}`).classList.add('a-flex-end');
    }

    const showLogin = () => {
        setShowDialog(true);
        setIsRegister(false);
        document.querySelector("#__next").classList.add("dialog-open")
    }

    const showRegister = () => {
        setShowDialog(true);
        setIsRegister(true);
        document.querySelector("#__next").classList.add("dialog-open")
    }

    const onHideDialog = () => {
        setShowDialog(false);
        document.querySelector("#__next").classList.remove("dialog-open")
    }

    const onToggleView = () => {
        setIsRegister(!isRegister)
    }

    const renderBodyModal = useMemo(() => {
        if (!isRegister) {
            return (
                <ModalLogin onToggleView={onToggleView} onHide={onHideDialog} />
            )
        } else {
            return (
                <ModalRegister onToggleView={onToggleView} onHide={onHideDialog} />
            )
        }
    }, [isRegister]);

    return (
        <>
            <Nav>
                <NavbarContainer className='container-fluid'>
                    <div className='d-flex'>
                        <Link legacyBehavior href="/" passHref>
                            <NavLogo>
                                <img src="/img/logo/logo_remake.png" alt="Octopus" title="Octopus" />
                            </NavLogo>
                        </Link>
                        <NavMenu>
                            <NavItem>
                                <Link legacyBehavior href="/" passHref>
                                    <a className='start-animation start-animation-1' onMouseEnter={(e) => onFocusIn(e, 1)} onMouseLeave={(e) => onFocusOut(e, 1)}>
                                        <div className='content-liner content-liner-1'>
                                            <span className={router.asPath === '/' ? 'active-text' : null}>Trang chủ</span>
                                        </div>
                                        <div className='liner liner-1' style={{ display: `block` }}>
                                        </div>
                                    </a>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link legacyBehavior href="/introduction" passHref>
                                    <a className='start-animation start-animation-2' onMouseEnter={(e) => onFocusIn(e, 2)} onMouseLeave={(e) => onFocusOut(e, 2)}>
                                        <div className='content-liner content-liner-2'>
                                            <span className={router.asPath === '/introduction' ? 'active-text' : null}>Giới thiệu</span>
                                        </div>
                                        <div className='liner liner-2' style={{ display: `block` }}>
                                        </div>
                                    </a>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link legacyBehavior href="/news" passHref>
                                    <a className='start-animation start-animation-3' onMouseEnter={(e) => onFocusIn(e, 3)} onMouseLeave={(e) => onFocusOut(e, 3)}>
                                        <div className='content-liner content-liner-3'>
                                            <span className={router.asPath === '/news' ? 'active-text' : null}>Tin tức</span>
                                        </div>
                                        <div className='liner liner-3' style={{ display: `block` }}>
                                        </div>
                                    </a>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link legacyBehavior href="/client" passHref>
                                    <a className='start-animation start-animation-4' onMouseEnter={(e) => onFocusIn(e, 4)} onMouseLeave={(e) => onFocusOut(e, 4)}>
                                        <div className='content-liner content-liner-4'>
                                            <span className={router.asPath === '/client' ? 'active-text' : null}>Khách hàng</span>
                                        </div>
                                        <div className='liner liner-4' style={{ display: `block` }}>
                                        </div>
                                    </a>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link legacyBehavior href="/contact" passHref>
                                    <a className='start-animation start-animation-5' onMouseEnter={(e) => onFocusIn(e, 5)} onMouseLeave={(e) => onFocusOut(e, 5)}>
                                        <div className='content-liner content-liner-5'>
                                            <span className={router.asPath === '/contact' ? 'active-text' : null}>Liên hệ</span>
                                        </div>
                                        <div className='liner liner-5' style={{ display: `block` }}>
                                        </div>
                                    </a>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link legacyBehavior href="/faq" passHref>
                                    <a className={router.asPath === '/faq' ? 'start-animation start-animation-6' : 'start-animation start-animation-6'} onMouseEnter={(e) => onFocusIn(e, 6)} onMouseLeave={(e) => onFocusOut(e, 6)}>
                                        <div className='content-liner content-liner-6'>
                                            <span className={router.asPath === '/faq' ? 'active-text' : null}>Câu hỏi thường gặp</span>
                                        </div>
                                        <div className='liner liner-6' style={{ display: `block` }}>
                                        </div>
                                    </a>
                                </Link>
                            </NavItem>
                            {account && (
                                <NavItem>
                                    <Link legacyBehavior href="/company" passHref>
                                        <a className={router.asPath === '/company' ? 'start-animation start-animation-6' : 'start-animation start-animation-7'} onMouseEnter={(e) => onFocusIn(e, 7)} onMouseLeave={(e) => onFocusOut(e, 7)}>
                                            <div className='content-liner content-liner-7'>
                                                <span className={router.asPath === '/company' ? 'active-text' : null}>Bảng điều khiển</span>
                                            </div>
                                            <div className='liner liner-7' style={{ display: `block` }}>
                                            </div>
                                        </a>
                                    </Link>
                                </NavItem>
                            )}
                        </NavMenu>
                    </div>
                    <Fragment>
                        {account && (
                            <NavMenu>
                                {renderButtonAccount}
                                {renderNotification()}
                            </NavMenu>
                        )}
                        {!account && (
                            <NavMenu>
                                <ButtonAction onClick={showRegister}>
                                    <NavItem>
                                        Đăng ký
                                    </NavItem>
                                </ButtonAction>
                                <ButtonAction onClick={showLogin}>
                                    <NavItem>
                                        Đăng nhập
                                    </NavItem>
                                </ButtonAction>
                            </NavMenu>
                        )}
                    </Fragment>
                    <MobileIcon onClick={toggleMenu} />
                </NavbarContainer>
            </Nav>
            <Sidebar isOpen={isOpen} onToggle={toggleMenu} account={account} showRegister={showRegister} showLogin={showLogin}/>
            <Dialog
                visible={showDialog}
                style={{ width: 449, border: "unset", borderRadius: "unset" }}
                onHide={onHideDialog}
                draggable={false}
                focusOnShow={false}
                className="dialog-login"
            >
                {renderBodyModal}
            </Dialog>
        </>
    )
}

export default MenuDefault;