import React, { useEffect, useState, Fragment, useRef } from 'react';
import * as actions from '@/redux/actions/index';
import * as fnc from '@/common/function';
import * as status from '@/common/enum'
import { useDispatch } from 'react-redux';
import { Messages } from 'primereact/messages';
import firebaseConfig from '../../../common/config/firebaseConfig';
import { initializeApp } from "firebase/app"
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useRouter } from 'next/router';
import ButtonDef from '@/components/button';
import Swal from 'sweetalert2';

const ModalRegister = ({ onToggleView }) => {
    const firebaseApp = initializeApp(firebaseConfig)
    const dispatch = useDispatch();
    const auth = getAuth(firebaseApp)
    const router = useRouter();
    const refMess = useRef(null);

    const [phone, setPhone] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [stepSocial, setStepSocial] = useState(0);
    const [confirmationResult, setconfirmationResult] = useState();
    const [socialId, setSocialId] = useState('');
    const [type, setType] = useState('');

    useEffect(() => {
        const facebookCallback = (response) => {
            if (response) {
                const facebookId = response.userID;
                setSocialId(facebookId);
                setType("FACEBOOK")
                // console.log(facebookId);
                actions.checkSocialExists({ type: "FACEBOOK", socialId: facebookId })
                    .then(response => {
                        if (response && response.status === status.STATUS_RESPONSE_OK) {
                            const {
                                data
                            } = response;
                            if (data && data.status === status.STATUS_RESPONSE_OK) {
                                Swal.fire({
                                    title: 'Cảnh báo',
                                    text: "Social ID này đã được liên kết với một tài khoản khác!",
                                    imageUrl: '/img/octopus/error.svg',
                                    imageWidth: 109,
                                    imageHeight: 98,
                                    imageAlt: 'Error',
                                    confirmButtonColor: '#76cc68',
                                    showCloseButton: true
                                })
                            } else {
                                setStepSocial(1);
                            }
                        } else {

                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                // dispatch(actions.fnLoginSocial({ type: "FACEBOOK", socialId: facebookId }, loginSocialEnd));
            }
        }

        window.facebookCallback = facebookCallback;
    }, [])

    const onLoginFacebook = (e) => {
        e.preventDefault();
        let _this = e.target;
        // _this.classList.add('btn-disabled');
        window.checkFacebookLoginState();
    }

    const sendOtp = async (e) => {
        //  e.preventDefault();
        setLoading(true);
        if (phone.toString().trim() === "") {
            refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: 'Vui lòng nhập số điện thoại !' });
            setLoading(false);
            return;
        }

        if (!fnc.isVietnamesePhoneNumber(phone)) {
            refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: 'Vui lòng nhập số điện thoại hợp lệ !' });
            setLoading(false);
            return;
        }

        await actions.registerUserOTP(phone).then(response => {
            const { data } = response
            if (data.status === status.STATUS_RESPONSE_OK) {
                const verify = new RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible'
                }, auth);

                signInWithPhoneNumber(auth, phone.replace(/^./g, '+84'), verify).then((result) => {
                    setconfirmationResult(result)
                    refMess.current.clear();
                    setLoading(false);
                    setStep(1);

                })
                    .catch((err) => {
                        setLoading(false);
                        refMess.current.show({ severity: 'error', summary: 'Lỗi', detail: err.toString() });
                    });
            }
            else {
                setLoading(false);
                refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: data.message });
            }
        }).catch((err) => {
            setLoading(false);
            refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: err.toString() });
        });


    }

    const resendOTP = async (e) => {
        const verify = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible'
        }, auth);

        await signInWithPhoneNumber(auth, phone.replace(/^./g, '+84'), verify).then((result) => {
            setconfirmationResult(result)
            refMess.current.clear();
            setLoading(false);
            setStep(1);

        })
            .catch((err) => {
                setLoading(false);
                refMess.current.show({ severity: 'error', summary: 'Lỗi', detail: err.toString() });
            });


    }

    const updateEnd = () => {
        router.push("/update-info");
        localStorage.setItem("require-update", true);
    }

    const register = (e) => {
        e.preventDefault();

        if (otpCode.toString().trim === "") {
            refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: 'Vui lòng nhập OTP !' });
            return;
        }

        confirmationResult.confirm(otpCode).then((result) => {
            dispatch(actions.fnLoginOtp({ username: phone, otp: '1234' }, updateEnd));
            // ...
        }).catch((error) => {
            console.log(error)
            refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: "Vui lòng điền đúng mã OTP! " });
        });



    }

    const registerSocial = async (e) => {
        e.preventDefault();

        setLoading(true);
        if (phone.toString().trim() === "") {
            refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: 'Vui lòng nhập số điện thoại !' });
            setLoading(false);
            return;
        }

        if (!fnc.isVietnamesePhoneNumber(phone)) {
            refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: 'Vui lòng nhập số điện thoại hợp lệ !' });
            setLoading(false);
            return;
        }

        const objSocial = {
            username: phone,
            type,
            socialId
        }

        await actions.registerSocial(objSocial)
        .then(response => {
            if(response && response.status === status.STATUS_RESPONSE_OK){
                const {
                    data
                } = response;

                if (data && data.status === status.STATUS_RESPONSE_OK) {
                    dispatch(actions.fnLoginSocial({ type, socialId }, updateEnd));
                }else{
                    Swal.fire({
                        title: 'Lỗi',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            }else{
                Swal.fire({
                    title: 'Lỗi',
                    text: response.message,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <Fragment>
            <div className='container'>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h4>ĐĂNG KÝ</h4>
                    </div>
                </div>
                <div className="row" style={{ marginBottom: 30 }}>
                    <div className="col-12 text-center">
                        <Messages ref={refMess} />
                    </div>
                </div>
                {stepSocial === 0 && (
                    <Fragment>
                        <div id="recaptcha-container"></div>
                        <div className="row">
                            <div className="col-md-6 text-center">
                                <input
                                    className="form-control"
                                    placeholder="Nhập số điện thoại"
                                    aria-label="Phone"
                                    aria-describedby="lblRegister"
                                    value={phone}
                                    style={{ minHeight: 41 }}
                                    type="tel"
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6 register-phone align-self-center">
                                <button onClick={onToggleView} className="btn-link" style={{ marginLeft: -15 }}>
                                    <span className="lnk-register">Đã có tài khoản</span>
                                </button>
                            </div>
                        </div>

                        {(step === 0) && (
                            <Fragment>
                                <div className="row" style={{ marginTop: 15 }}>
                                    <div className="col-md-6">
                                        <ButtonDef
                                            id="btnOTP"
                                            label="ĐĂNG KÝ"
                                            // id="btnLogin"
                                            variant="warning"
                                            isShadow={true}
                                            onClick={sendOtp}
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                                <div className="p-grid" style={{ paddingTop: 10 }}>
                                    <div className="p-col-12 text-center">
                                        <span style={{ fontFamily: "Roboto", fontSize: 12, fontWeight: 400 }}>Hoặc</span>
                                    </div>
                                </div>
                                <div className="p-grid" style={{ paddingTop: 10 }}>
                                    <div className="p-col-12 text-center">
                                        <button className="btn-facebook text-start" onClick={onLoginFacebook}>
                                            <img src="/img/icons/btn-facebook.svg" style={{ pointerEvents: `none` }} />&nbsp;&nbsp;&nbsp;Đăng nhập bằng Facebook
                                        </button>
                                    </div>
                                </div>
                                <div className="p-grid" style={{ paddingTop: 15, marginBottom: 50 }}>
                                    <div className="p-col-12 text-center">
                                        <button className="btn-gmail text-start">
                                            <img src="/img/icons/btn-gmail.svg" style={{ width: 19, height: 19, marginLeft: 4 }} />&nbsp;&nbsp;&nbsp;&nbsp;Đăng nhập bằng Gmail
                                        </button>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        {(step === 1) && (
                            <div style={{ marginTop: 15, marginBottom: 50 }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input
                                            className='form-control'
                                            placeholder="Nhập OTP"
                                            aria-label="Phone"
                                            aria-describedby="lblRegister"
                                            value={otpCode}
                                            style={{ minHeight: 41 }}
                                            type="tel"
                                            onChange={(e) => setOtpCode(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6" style={{ marginLeft: -15 }}>
                                        <ButtonDef
                                            id="btnAccept"
                                            label="XÁC NHẬN"
                                            // id="btnLogin"
                                            variant="warning"
                                            isShadow={true}
                                            onClick={register}
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: 15 }}>
                                    <div className="col-md-12">
                                        <button onClick={resendOTP} className="btn-link">
                                            <span className="lnk-register">Gửi lại mã OTP</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
                {stepSocial === 1 && (
                    <Fragment>
                        <div className="row">
                            <div className="col-md-12">
                                <input
                                    className="form-control"
                                    placeholder="Nhập số điện thoại"
                                    aria-label="Phone"
                                    aria-describedby="lblRegister"
                                    value={phone}
                                    style={{ minHeight: 41 }}
                                    type="tel"
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="p-grid" style={{ margin: 50 }}>
                            <div className="p-col-12 text-center d-flex justify-content-center">
                                <ButtonDef
                                    label="ĐĂNG KÝ"
                                    id="btnLogin"
                                    variant="warning"
                                    isShadow={true}
                                    onClick={registerSocial}
                                />
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}

export default ModalRegister;