import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";

const Error = () => {
    const refToast = useRef();
    const error = useSelector((state) => state.error)
    useEffect(() => {
        const {
            detail,
            severity,
            summary,
        } = error;
        if (detail !== null && detail !== "" && severity !== null && severity !== "" && summary !== null && summary !== "") {
            refToast.current.show({ severity, summary, detail })
        }
    }, [error])

    return (
        <Toast ref={refToast} />
    )
}

export default Error;
