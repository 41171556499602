// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCeMKWNGI5FVeVxuRu5l_SbOveHfCe19O4",
  authDomain: "tengroup-octopus.firebaseapp.com",
  projectId: "tengroup-octopus",
  storageBucket: "tengroup-octopus.appspot.com",
  messagingSenderId: "630501663756",
  appId: "1:630501663756:web:737ca86eae6e9d3e903a29",
  measurementId: "G-PTW5958ZCQ"
};

// Initialize Firebase
//const app = initializeApp(firebaseConfig);

export default firebaseConfig
