import styled from "styled-components";

export const Nav = styled.nav`
    background-color: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;

    @media only screen and (max-width: 1120px) {
        transition: 0.8s all ease;
        width: 100%;
    }

    .none-event{
        pointer-events: none;
    }

    .active-text{
        color: #F3941F;
        font-weight: bold;
    }
`;

export const NavbarContainer = styled.div`
    max-width: 1800px;
    padding-left: 10px!important;
    padding-right: 10px!important;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    @media only screen and (max-width: 1120px) {
        max-width: 100%;
    }
`;

export const NavLogo = styled.div`
    display: flex;
    height: 60px;
    width: 60px;
    font-weight: bold;
    text-decoration: none;
    justify-self: center;

    &:focus{
        outline: none;
        box-shadow: none;
    }

    &:img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
    }

    @media only screen and (max-width: 1120px) {
        height: 50px;
        img {
            width: 100%;
            height: 50px;
            object-fit: contain;
            cursor: pointer;
        }
    }
`;

export const MobileIcon = styled.button`
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='24' viewBox='0 0 26 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.9995 10L9.99951 10' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.9995 16L3.99951 16' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.9995 22H3.99951' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 28px;
    border: unset;
    background-color: unset;
    width: 24px; 
    height: 30px;
    display: none;
    cursor: pointer;
    margin-right: 10px;

    @media only screen and (max-width: 1120px) {
        display: block;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    height: 60px;
    margin: 0;
    padding-left: 0;

    @media only screen and (max-width: 1120px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 0 15px;
    cursor: pointer;
    position: relative;
    height: 100%;
    transition: 0.2s all ease-in-out;

    & > a {
        color: #000;
        width: 100%;
        justify-content: center;
        position: relative;
        flex-direction: column;
    }

    & > .liner-hidden{
        visibility: hidden;
    }

    & > .liner-visible{
        visibility: visible;
    }

    & > .a-flex-start{
        display: flex;
        align-items: flex-start;
    }

    & > .a-flex-end{
        display: flex;
        align-items: flex-end;
    }

    @keyframes line-left-right {
        0% {
            width: 0%;
            visibility: visible;
        }

        100% {
            width: 100%;
        }
    }


    @keyframes line-right-left {
        0% {
            width: 100%;
            visibility: visible;
        }

        100% {
            width: 0%;
            visibility: hidden;
        }
    }

    a:hover{
        text-decoration: none;
    }

    .content-liner{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: calc(100% - 2px);
    }

    .liner{            
        width: 100%;
        height: 2px;
        background-color: #F3941F;
        visibility: hidden
    }  

    .line-start{
        animation-name: line-left-right;
        animation-duration: 0.5s;
    }

    .line-end{
        animation-name: line-right-left;
        animation-duration: 0.5s;
    }

    .showLiner{
        display: block;
    }
`;