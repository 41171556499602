import { Fragment, useEffect } from 'react';
import { SidebarContainer, CloseIcon, Icon, SidebarMenu, SidebarWrapper, SidebarMenuItem } from './element';
import Link from 'next/link';
import { useRouter } from 'next/router';
const Sidebar = ({ isOpen, onToggle, account, showRegister, showLogin }) => {
    const router = useRouter();

    return (
        <SidebarContainer isOpen={isOpen}>
            <Icon onClick={onToggle}>
                <CloseIcon className='pi pi-times'></CloseIcon>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarMenuItem>
                        <Link legacyBehavior href={'/'} passHref>
                            <a className={router && router.asPath === `/` ? 'text-menu active' : `text-menu`}>
                                <span>Trang chủ</span>
                            </a>
                        </Link>
                    </SidebarMenuItem>
                    <SidebarMenuItem>
                        <Link legacyBehavior href={'/introduction'} passHref>
                            <a className={router && router.asPath === `/introduction` ? 'text-menu active' : `text-menu`}>
                                <span>Giới thiệu</span>
                            </a>
                        </Link>
                    </SidebarMenuItem>
                    <SidebarMenuItem>
                        <Link legacyBehavior href={'/news'} passHref>
                            <a className={router && router.asPath === `/news` ? 'text-menu active' : `text-menu`}>
                                <span>Tin tức</span>
                            </a>
                        </Link>
                    </SidebarMenuItem>
                    <SidebarMenuItem>
                        <Link legacyBehavior href={'/client'} passHref>
                            <a className={router && router.asPath === `/client` ? 'text-menu active' : `text-menu`}>
                                <span>Khách hàng</span>
                            </a>
                        </Link>
                    </SidebarMenuItem>
                    <SidebarMenuItem>
                        <Link legacyBehavior href={'/contact'} passHref>
                            <a className={router && router.asPath === `/contact` ? 'text-menu active' : `text-menu`}>
                                <span>Liên hệ</span>
                            </a>
                        </Link>
                    </SidebarMenuItem>
                    <SidebarMenuItem>
                        <Link legacyBehavior href={'/faq'} passHref>
                            <a className={router && router.asPath === `/faq` ? 'text-menu active' : `text-menu`}>
                                <span>Câu hỏi thường gặp</span>
                            </a>
                        </Link>
                    </SidebarMenuItem>
                    {account ? (
                        <>
                            <SidebarMenuItem>
                                <Link legacyBehavior href={'/my-profile'} passHref>
                                    <a className={router && (router.asPath === `/my-profile` || router.asPath === `/my-profile#ng`) ? 'text-menu active' : `text-menu`}>
                                        <span>Tài khoản của tôi</span>
                                    </a>
                                </Link>
                            </SidebarMenuItem>
                            <SidebarMenuItem>
                                <Link legacyBehavior href={'/my-profile#nt'} passHref>
                                    <a className={router && router.asPath === `/my-profile#nt` ? 'text-menu active' : `text-menu`}>
                                        <span>Thông báo của tôi</span>
                                    </a>
                                </Link>
                            </SidebarMenuItem>
                        </>
                    ) : (
                        <>
                            <SidebarMenuItem>
                                <a className="text-menu" onClick={showLogin}>
                                    <span>Đăng nhập</span>
                                </a>
                            </SidebarMenuItem>
                            <SidebarMenuItem>
                                <a className="text-menu" onClick={showRegister}>
                                    <span>Đăng ký</span>
                                </a>
                            </SidebarMenuItem>
                        </>
                    )}
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar;