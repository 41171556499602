import { useEffect, useState, Fragment, useRef } from 'react';
import { Messages } from 'primereact/messages';
import * as actions from '@/redux/actions/index';
import * as fnc from '@/common/function';
import firebaseConfig from '@/common/config/firebaseConfig';

import { useDispatch, useSelector } from 'react-redux';
import { initializeApp } from "firebase/app"
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import ButtonDef from '@/components/button';
import Swal from 'sweetalert2';
const ModalLogin = ({ onToggleView, onHide }) => {

    const firebaseApp = initializeApp(firebaseConfig)
    const auth = getAuth(firebaseApp)

    const dispatch = useDispatch();
    const loading = useSelector(state => state.auth.loading);
    const refMess = useRef(null);
    const [username, setUsername] = useState("");
    const [otp, setOtp] = useState("");
    const [loadingOtp, setLoadingOtp] = useState(false);
    const [loadingTime, setLoadingTime] = useState(false);
    const [showInputOtp, setShowInputOtp] = useState(0);
    const [confirmationResult, setconfirmationResult] = useState();

    const [facebookStatus, setFacebookStatus] = useState(0);

    useEffect(() => {
        const facebookCallback = (response) => {
            if (response) {
                const facebookId = response.userID;
                dispatch(actions.fnLoginSocial({ type: "FACEBOOK", socialId: facebookId }, loginSocialEnd));
            }
        }

        window.facebookCallback = facebookCallback;
    }, [])

    const sendOtp = (e) => {
        e.preventDefault();
        if (username.toString().trim() === "") {
            refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: 'Vui lòng nhập số điện thoại !' });
            return;
        }

        if (!fnc.isVietnamesePhoneNumber(username)) {
            refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: 'Vui lòng nhập số điện thoại hợp lệ !' });
            return;
        }
        setLoadingOtp(true)
        actions.checkUserExists(username).then(response => {
            const { status, message,isWhiteList } = response.data;
            if (status !== 200) {
                setLoadingOtp(false);
                refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: message });
            }
            else {
                if(isWhiteList){
                    dispatch(actions.fnLoginOtp({ username, otp: '1234' }, updateEnd));
                }
                else{
                    const verify = new RecaptchaVerifier('recaptcha-container', {
                        'size': 'invisible'
                    }, auth);
    
                    signInWithPhoneNumber(auth, username.replace(/^./g, '+84'), verify).then((result) => {
                        setconfirmationResult(result)
                        refMess.current.clear();
                        actions.sendOtp(username).then(response => {
                        }).finally(() => {
                            setLoadingOtp(false);
                            setShowInputOtp(1);
                        });
                    })
                        .catch((err) => {
                            setLoadingOtp(false);
                            refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: err.toString() });
                        });
                }

               
            }

        })
    }

    const updateEnd = () => {
        window.location.reload();
        onHide();
    }

    const resendOtp = (e) => {
        e.preventDefault();
        let _this = e.target;
        setLoadingTime(true);

        _this.classList.add('btn-disabled');
    }

    const fnLoginOtp = (e) => {
        e.preventDefault();

        if (otp.toString().trim === "") {
            refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: 'Vui lòng nhập OTP !' });
            return;
        }

        confirmationResult.confirm(otp).then((result) => {
            dispatch(actions.fnLoginOtp({ username, otp: '1234' }, updateEnd));
            // ...
        }).catch((error) => {
            refMess.current.show({ severity: 'warn', summary: 'Lỗi', detail: "Vui lòng điền đúng mã OTP! " });
        });
    }

    const onLoginFacebook = (e) => {
        e.preventDefault();
        let _this = e.target;
        _this.classList.add('btn-disabled');
        window.checkFacebookLoginState();
    }

    const loginSocialEnd = (social, error) => {
        if (error.message && error.status === 404) {
            Swal.fire({
                title: 'Lỗi',
                text: error.message,
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        } else {
            updateEnd();
        }
        const buttonElement = document.querySelector(`.btn-${social.toLowerCase()}`);
        buttonElement.classList.remove('btn-disabled');
    }

    return (
        <Fragment>
            <div className='container'>
                <div className="p-grid">
                    <div className="p-col-12 text-center">
                        <h4>ĐĂNG NHẬP</h4>
                    </div>
                </div>
                <div className="p-grid" style={{ paddingTop: 35 }}>
                    <div className="p-col-12 text-center">
                        <Messages ref={refMess} />
                    </div>
                </div>
                <div className="p-grid" style={{ paddingTop: 15 }}>
                    <div id="recaptcha-container"></div>
                    <div className="p-col-6 text-center">
                        <input
                            className='form-control'
                            type="tel"
                            id="txtUsername"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Nhập số điện thoại"
                            style={{ width: `100%` }} maxLength="10" />
                    </div>
                    <div className="p-col-6 p-nogutter align-self-center p-grid">
                        <button onClick={onToggleView} className="btn-link">
                            <span className="lnk-register">Đăng ký tài khoản</span>
                        </button>
                    </div>
                </div>
                {(showInputOtp === 1) && (
                    <Fragment>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <span style={{ fontFamily: "Roboto", fontSize: 12, fontWeight: 400 }}>Nhập OTP</span>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-6 text-center">
                                <input
                                    className='form-control'
                                    type="text"
                                    id="txtOTP"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    placeholder="000000"
                                    style={{ width: `100%` }}
                                    maxLength="6"
                                />
                            </div>
                            <div className="p-col-6 p-nogutter align-self-center p-grid">
                                <ButtonDef
                                    label="XÁC NHẬN"
                                    variant="warning"
                                    isShadow={true}
                                    onClick={fnLoginOtp}
                                    loading={loading}
                                />
                            </div>
                        </div>
                        <div className="p-grid" style={{ paddingBottom: 60 }}>
                            <div className="p-col-12">
                                <button className="btn-link" onClick={resendOtp}>
                                    <span className="lnk-register" style={{ pointerEvents: `none` }}>Gửi lại mã OTP</span>
                                </button>
                            </div>
                        </div>
                    </Fragment>
                )}
                {(showInputOtp === 0) && (
                    <Fragment>
                        <div className="p-grid" style={{ paddingTop: 10 }}>
                            <div className="p-col-12 text-center">
                                <span style={{ fontFamily: "Roboto", fontSize: 12, fontWeight: 400 }}>Hoặc</span>
                            </div>
                        </div>
                        <div className="p-grid" style={{ paddingTop: 10 }}>
                            <div className="p-col-12 text-center">
                                <button className="btn-facebook text-start" onClick={onLoginFacebook}>
                                    <img src="/img/icons/btn-facebook.svg" style={{ pointerEvents: `none` }} />&nbsp;&nbsp;&nbsp;Đăng nhập bằng Facebook
                                </button>
                            </div>
                        </div>
                        {facebookStatus === 404 && (
                            <div className='p-grid'>
                                <div className="p-col-12">
                                    <span style={{ fontFamily: "Roboto", fontSize: 12, fontWeight: 400, marginLeft: 15, color: `red` }}>Social ID chưa tồn tại! Bạn có muốn
                                        &nbsp;<a className='link' onClick={onToggleView}>Đăng ký</a> ?
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="p-grid" style={{ paddingTop: (!facebookStatus) ? 15 : 0 }}>
                            <div className="p-col-12 text-center">
                                <button className="btn-gmail text-start">
                                    <img src="/img/icons/btn-gmail.svg" style={{ width: 19, height: 19, marginLeft: 4 }} />&nbsp;&nbsp;&nbsp;&nbsp;Đăng nhập bằng Gmail
                                </button>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
            {(showInputOtp === 0) && (
                <div className="p-grid" style={{ margin: 50 }}>
                    <div className="p-col-12 text-center d-flex justify-content-center">
                        <ButtonDef
                            label="ĐĂNG NHẬP"
                            id="btnLogin"
                            variant="warning"
                            isShadow={true}
                            onClick={sendOtp}
                            loading={loadingOtp}
                        />
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default ModalLogin;