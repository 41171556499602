import styled from "styled-components";

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.9);
    color: #000;
    display: grid;
    align-items: center;
    left: 0;
    transition: 0.3s all ease-in-out;
    top: ${({ isOpen }) => (isOpen ? "0" : "-200%")};
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
`;

export const CloseIcon = styled.i`
    color: #000;
    pointer-events: none;
    font-size: 1.5rem;
`;

export const Icon = styled.button`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: unset;
`;

export const SidebarWrapper = styled.div`
    color: #fff;
`;

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    text-align: center;
    list-style: none;
    margin: 0;
    padding-left: 0;

    @media (min-width: 1280px) {

    }
`;
export const SidebarMenuItem = styled.li`
    font-family: 'Roboto', sans-serif;    
    font-size: 1.5rem;
    font-weight: 500;
    padding: 20px 10px;
    color: #000;

    & > .text-menu {
        color: #000;
    }

    & > a.text-menu:hover{
        color: #F3941F;
        text-decoration: none;
    }

    & > a.text-menu:focus{
        box-shadow: none;
        outline: none;
    }

    &> a.active {
        color: #F3941F;
    }
`